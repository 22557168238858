import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 2-2-2-2-2 to a 2Rm`}</p>
    <p>{`Glute Ham Raise 4-4-4-4-4`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 EMOM of:`}</p>
    <p>{`1-Power Clean`}</p>
    <p>{`1-Squat Clean`}</p>
    <p>{`1-Hang Squat Clean`}</p>
    <p>{`*`}{`Using 75% 1RM of lightest movement`}</p>
    <p>{`then at minute 11:00`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`20-KBS (53/35)`}</p>
    <p>{`15-Box Jump Overs (24/20)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      